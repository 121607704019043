.NewYork ._sidebar_background {
    position: absolute;
    height: calc(var(--sidebar-background-height) - 60px);
    width: calc(var(--sidebar-background-width) - 35px);
    background: var(--color-primary) !important;
    top: 30px;
    left: calc(var(--left-column) + 30px);
    right: calc(var(--right-column) + 30px);
    z-index: 0;
    border-radius: 4px;
}

.NewYork ._topbar_background {
    position: absolute;
    height: calc(36px * var(--scale-factor));
    width: calc(100% - 100px);
    background: var(--color-primary) !important;
    top: calc(var(--sidebar-background-height) - 46px* var(--scale-factor));
    left: 50px;
    z-index: 0;
    border-radius: 4px;
}

.NewYork ._sidebar_column {
    width: var(--sidebar-width);
    flex-shrink: 0;
    color: var(--color-reverse);
    z-index: 1;
}

.NewYork ._header {
    margin-bottom: calc(30px * var(--density-factor));
    position: relative;
    z-index: 1;
    color: var(--color-reverse);
}

.NewYork ._personal_info {
    display: flex;
    justify-content: center;
}

.NewYork ._grid_2x {
    margin-top: calc(20px * var(--density-factor));
}

._contacts_panel_center {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 4px;
    font-style: normal;
    align-items: center;
    line-height: 1.0em;
    /* margin-bottom: 10px; */
}

._contacts_panel_side {
    display: flex;
    flex-direction: column;
    gap: calc(6px * var(--density-factor));
    font-style: normal;
    align-items: var(--sidebar-background-font-alignment);
    margin-top: calc(20px* var(--density-factor));
}

.NewYork ._name_panel {
    margin-bottom: 4px;
}

.NewYork ._contacts_panel_side a,
.NewYork ._contacts_panel_side a:visited,
.NewYork ._contacts_panel_side a:hover,
.NewYork ._contacts_panel_side a:active,
.NewYork ._contacts_panel_center a,
.NewYork ._contacts_panel_center a:visited,
.NewYork ._contacts_panel_center a:hover,
.NewYork ._contacts_panel_center a:active {
    text-decoration: underline;
    color: var(--color-reverse);
}

.NewYork ._custom_link:not(:first-child):before {
    content: "\a0\007C";
    display: inline-block;
    margin-right: 6px;
}

.NewYork ._info_panel {
    flex-direction: column;
    align-items: center;
}

.NewYork .PositionTop {
    top: 20px;
}

.NewYork ._page_container {
    --sidebar-width: calc(260px * var(--scale-factor));
    --color-reverse: #fbfaf7;
}

._section_side_header {
    margin-bottom: calc(40px* var(--density-factor));
}

.NewYork .PositionLeft {
    left: 20px;
}

.NewYork .PositionRight {
    right: 20px;
}