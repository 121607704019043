.Tokyo ._sidebar_background {
    position: absolute;
    height: calc(var(--sidebar-background-height) - 60px);
    width: calc(var(--sidebar-background-width) - 35px);
    background: var(--color-primary) !important;
    opacity: 0.05;
    top: 30px;
    left: calc(var(--left-column) + 30px);
    right: calc(var(--right-column) + 30px);
    z-index: 0;
    opacity: 0.05;
    color: var(--color-foreground);
    border-radius: 4px;
}

.Tokyo ._topbar_background {
    position: absolute;
    height: calc(106px * var(--scale-factor));
    width: calc(100% - 100px);
    background: var(--color-primary) !important;
    border-radius: 4px;
    opacity: 0.05;
    top: calc(var(--sidebar-background-height) - 116px* var(--scale-factor));
    left: 50px;
    z-index: 0;
}

.Tokyo ._sidebar_column {
    width: var(--sidebar-width);
    flex-shrink: 0;
    z-index: 1;
}

.Tokyo ._header {
    margin-bottom: calc(30px * var(--density-factor));
    position: relative;
    z-index: 1;
}

.Tokyo ._personal_info {
    display: flex;
    justify-content: center;
}

.Tokyo ._grid_2x {
    margin-top: calc(20px * var(--density-factor));
}

.Tokyo ._name_panel {
    margin-bottom: 4px;
    text-transform: uppercase;
    font-weight: 700;
}

.Tokyo ._contacts_panel_side a,
.Tokyo ._contacts_panel_side a:visited,
.Tokyo ._contacts_panel_side a:hover,
.Tokyo ._contacts_panel_side a:active,
.Tokyo ._contacts_panel_center a,
.Tokyo ._contacts_panel_center a:visited,
.Tokyo ._contacts_panel_center a:hover,
.Tokyo ._contacts_panel_center a:active {
    text-decoration: underline;
    color: var(--color-foreground);
}

.Tokyo ._resume_title {
    text-transform: uppercase;
    color: var(--color-primary);
    font-weight: 500;
}

.Tokyo ._custom_link:not(:first-child):before {
    content: "\a0\007C";
    display: inline-block;
    margin-right: 6px;
    color: var(--color-primary);
}

.Tokyo ._info_panel {
    flex-direction: column;
    align-items: center;
}

.Tokyo .PositionTop {
    top: 20px;
}

.Tokyo ._page_container {
    --sidebar-width: calc(260px * var(--scale-factor));
    --color-reverse: #fbfaf7;
}

.Tokyo .PositionLeft {
    left: 20px;
}

.Tokyo .PositionRight {
    right: 20px;
}