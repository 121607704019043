.London ._header {
    position: relative;
    margin-bottom: 0;
    padding-bottom: calc(8px * var(--density-factor));
}

.London ._name_panel {
    display: flex;
    justify-content: center;
    color: var(--color-primary);
}

/* Horizontal separator after header */
.London hr {
    border-top: 1px solid #a09e9e;
}

.London ._resume_section {
    margin-bottom: calc(4px * var(--density-factor));
    margin-top: calc(4px * var(--density-factor));
}

.London ._section_card_more_margin {
    margin-top: calc(12px * var(--density-factor));
}

.London ._resume_title {
    color: var(--color-primary);
}

._personal_info_section {
    display: flex;
    justify-content: space-between;
    margin-bottom: calc(8px * var(--density-factor));
}

._contacts_section {
    font-size: calc(20px * var(--scale-factor) * var(--font-factor));
    line-height: 1.0em;
    color: var(--color-foreground);
    display: flex;
    gap: 4px;
    justify-content: center;
    margin: 10px 0;
}

._contacts_section a,
._contacts_section a:visited,
._contacts_section a:hover,
._contacts_section a:active {
    text-decoration: none;
    color: #0678be;
}

.London ._custom_link:not(:first-child):before {
    content: "\a0\007C";
    color: var(--color-foreground);
    display: inline-block;
    margin-right: 4px;
}

.London ._labelTop {
    display: block;
    width: 49px;
    height: 49px;
    color: var(--color-primary);
    /* Position it at the top right margin */
    right: 32px;
    top: 32px;
    transform: scale(var(--scale-factor));
    transform-origin: 100% 0;
}

.London ._sidebar_column {
    width: var(--sidebar-width);
    flex-shrink: 0;
    gap: calc(12px * var(--density-factor));
}

.London ._page_container {
    --color-foreground: #444242;
    --columns-gap: calc(30px * var(--density-factor));
}