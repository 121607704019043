#Resume {
    margin: 0;
    height: 100%;
}

._page_resume_template {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background: white;
    word-wrap: break-word;
    height: 100%;
    width: 100%;
    box-shadow: var(--shadow-elevation-four);
    border-radius: var(--card-border-radius);
    border: var(--border-thickness-normal) solid var(--color-card-border);
    padding: var(--template-padding);
    font-family: var(--font-theme);
    font-size: calc(20px * var(--scale-factor) * var(--font-factor));
    line-height: calc(1.63 * var(--density-factor));
    color: var(--color-foreground);
    background-color: var(--color-background);
}

.SanFrancisco ._sidebar_column {
    width: var(--sidebar-width);
    flex-shrink: 0;
}

._header {
    margin-bottom: calc(20px * var(--density-factor))
}

.SanFrancisco._header {
    margin-bottom: calc(28px * var(--density-factor))
}

._info_panel {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
}

._personal_info {
    min-width: 0;
    width: 100%
}

._name_panel {
    /* font-family: var(--font-theme); */
    font-size: calc(45px * var(--scale-factor));
    line-height: 1.0em;
    font-weight: 900;
    margin: 0;
}

._contacts_panel {
    width: var(--sidebar-width);
    flex-shrink: 0;
    display: flex;
    align-items: center;
    gap: 10px;
    text-wrap: nowrap;
    line-height: 1.0em;
}

._font_italic {
    /* font-style: italic; */
    color: #777575;
}

._contacts_panel a,
._contacts_panel a:visited,
._contacts_panel a:hover,
._contacts_panel a:active {
    text-decoration: none;
    color: #0678be;
}

._sidebar_card a,
._sidebar_card a:visited,
._sidebar_card a:hover,
._sidebar_card a:active {
    text-decoration: none;
    color: #0678be;
}

._labelTop {
    width: 60px;
    height: 142px;
    position: absolute;
    top: 0;
    right: calc(80px * var(--scale-factor));
    color: var(--color-primary);
    transition: color var(--transition-time);
    transform: scale(var(--scale-factor));
    transform-origin: 50% 0
}

._labelBottom {
    width: 60px;
    height: 65px;
    position: absolute;
    bottom: 0;
    right: calc(80px * var(--scale-factor));
    color: var(--color-primary);
    transition: color var(--transition-time);
    transform: scale(var(--scale-factor));
    transform-origin: 50% 100%
}

._resume_columns {
    display: flex;
    flex-grow: 1;
    width: 100%;
    min-height: 0;
    gap: var(--columns-gap);
}

._column {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: 0
}

._main_content {
    width: 100%;
    min-width: 0
}

._resume_section {
    margin-bottom: calc(10px * var(--density-factor));
    margin-top: calc(10px * var(--density-factor));
}

/* Set top margin = 0 for the first resume section under the _sidebar_column */
._sidebar_column ._resume_section:first-child {
    margin-top: 0;
}

._center_column {
    display: flex;
    flex-direction: column;
    grid-template-columns: repeat(2, calc(50% - 12px));
    column-gap: calc(24px * var(--density-factor));
}

._list_row {
    padding-left: 5px;
}

.summary {
    display: flex;
    flex-direction: column;
}

._resume_card {
    display: flex;
    flex-direction: column;
    margin-bottom: calc(12px * var(--density-factor));
}

._resume_paragraph {
    display: flex;
    flex-direction: column;
    font-size: calc(20px * var(--scale-factor) * var(--font-factor));
    line-height: calc(1.63 * var(--density-factor));
    margin-bottom: calc(4px * var(--density-factor));
    margin-top: 0;
    text-align: justify;
}

._resume_date {
    font-size: calc(20px * var(--scale-factor) * var(--font-factor));
    line-height: calc(1.6 * var(--density-factor));
    color: var(--color-neutral);
}


._section_title {
    font-weight: 600;
    font-size: calc(22px * var(--scale-factor) * var(--font-factor));
}

._section_subtitle {
    font-size: calc(22px * var(--scale-factor) * var(--font-factor));
}

._header_with_date {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

/* Horizontal separator after header */
hr {
    border: none;
    border-top: 1px solid #e2e0e0;
    margin: 2px 0 6px 0;
}

._custom_list {
    margin: 2px 0;
    padding-left: 16px;
}

._custom_list li div {
    display: inline;
}

._custom_list li div._header_with_date {
    display: flex;
}

.SanFrancisco ._custom_list {
    list-style-type: square;
}

._resume_title {
    margin: calc(8px * var(--density-factor)) 0;
    font-size: calc(28px * var(--scale-factor) * var(--font-factor));
    font-weight: 700;
    line-height: calc(1.277 * var(--density-factor));
    position: relative;
    display: flex;
    gap: 0.25rem
}

._less_margin {
    margin-bottom: calc(4px * var(--density-factor));
    margin-top: 0;
}

._more_margin {
    margin-bottom: calc(36px * var(--density-factor));
}

._resume_grid {
    display: grid;
    row-gap: calc(10px * var(--density-factor));
    padding-top: calc(10px * var(--density-factor));
}

._sidebar_card {
    margin: 0;
    display: inline;
}

/* New styles for centered alignment */
._summary_center_layout {
    display: flex;
}

._card_row {
    margin-bottom: calc(12px * var(--density-factor))
}

._section_card_more_margin {
    margin-bottom: calc(4px * var(--density-factor))
}

._summary_center_layout ._resume_title {
    min-width: var(--sidebar-width);
    margin-right: calc(16px * var(--scale-factor));
    margin-bottom: 0;
    margin-top: 0;
}

/* ._summary_center_layout ._resume_paragraph {margin-top: 0;} */
._left_column_center_layout {
    width: var(--sidebar-width);
    flex-shrink: 0;
    margin-right: calc(16px * var(--scale-factor));
    margin-top: calc(2px * var(--density-factor));
}

._card_center_layout {
    display: flex;
    word-break: break-word;
}

._grid_2x {
    display: grid;
    grid-template-columns: var(--grid-density-factor);
    /*This changes the num columns from 2 to 3 for compact layout*/
    /* grid-template-columns: repeat(2, 50%); */
    column-gap: calc(10px * var(--density-factor));
    row-gap: calc(4px * var(--density-factor));
}

._content._full {
    height: 100%;
}

._content._page {
    height: var(--page-height);
}

._page_container {
    display: flex;
    flex-direction: column;
    row-gap: var(--resume-page-gap);
    /* Other Settings */
    --color-neutral: #777575;
    --color-foreground: #444242;
    --color-background: white;
    --sidebar-width: calc(200px * var(--scale-factor));
    --columns-gap: calc(50px * var(--density-factor));
    text-align: left;
    /* Dimensions */
    width: var(--page-width);
    --page-scale-factor: var(--print-scale-factor, var(--scale-factor, 1));
    transform: scale(var(--page-scale-factor));
    transform-origin: top left;
}

._keywords {
    z-index: -1;
    position: absolute;
    left: var(--key-left);
    right: var(--key-right);
    top: var(--key-top);
    width: var(--key-width);
    height: var(--key-height);
    background-color: white;
    overflow: hidden;
    white-space: break-spaces;
    color: white;
    padding-top: 4px;
    padding-left: 4px;
    font-size: 12px;
}

.PrimaryColor {
    color: var(--color-primary);
}

.PositionTop {
    top: 2.5rem;
}

.PositionLeft {
    left: 0.5rem;
}

.PositionRight {
    right: 0.5rem;
}

.SanFrancisco.PositionLeft {
    left: 2.25rem;
}

.SanFrancisco .PositionRight {
    right: 2.25rem;
}