/* App Styles */
html {
  height: 100%;
  font-size: 16px;
  /* Default base unit for 1 rem */
}

:root {
  --editor-panel-max-width: 630px;
}

/* Media query for lower resolution displays i.e. less than 1280px wide */
@media screen and (max-width: 1280px) {
  html {
    font-size: 13px;
    /* Lower base unit for 1 rem */
  }

  :root {
    --editor-panel-max-width: 414px;
  }
}

/* Media query for lower resolution displays between 1280px and 1536px */
@media screen and (min-width: 1281px) and (max-width: 1536px) {
  html {
    font-size: 14px;
    /* Lower base unit for 1 rem */
  }
}

/* Media query for screen widths between 1280px and 1920px */
@media screen and (min-width: 1281px) and (max-width: 1920px) {
  :root {
    --editor-panel-max-width: calc(414px + 0.3375 * (100vw - 1280px));
  }
}

/* Media query for higher resolutions i.e. > 1920px */
@media screen and (min-width: 1921px) {
  html {
    font-size: 17px;
    /* Higher base unit for 1 rem */
  }
}

body {
  margin: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: var(--body-justify, center);
  overflow-y: scroll;
}

#app {
  /* Application Variables */
  /* Shadows */
  --shadow-elevation-one: 0 0.1875rem 0.1875rem rgba(78, 82, 85, 0.027), 0.1875rem 0.1875rem 0.5rem rgba(78, 82, 85, 0.072), -0.1875rem 0.1875rem 0.5rem rgba(78, 82, 85, 0.072);
  --shadow-elevation-two: 0 0.25rem 0.75rem rgba(92, 92, 108, 0.16), 0 0.125rem 0.125rem rgba(39, 39, 42, .04);
  --shadow-elevation-three: 0rem 0rem 0rem 0.0625rem rgb(140 140 140/.2), 0rem 0.25rem 0.25rem rgb(0 0 0/.3);
  --shadow-elevation-four: 0 0.1875rem 0.1875rem rgba(78, 82, 85, 0.03), 0.1875rem 0.1875rem 0.1875rem rgba(78, 82, 85, 0.08);
  /* Colors */
  --secondary-10: #f7eef2;
  --secondary-50: #f8e7f1;
  --secondary-100: #f0c3de;
  --secondary-200: #ea9dc9;
  --secondary-300: #e578b3;
  --secondary-400: #e25fa0;
  --secondary-500: #e24c8e;
  --secondary-600: #d04988;
  --secondary-700: #b9447f;
  --secondary-800: #a24076;
  --secondary-900: #7a3966;
  --primary-10: #e3f3f9;
  --primary-50: #e1f5fe;
  --primary-100: #b3e6fc;
  --primary-200: #82d5fa;
  --primary-300: #50c4f8;
  --primary-400: #2bb7f7;
  --primary-500: #0aabf5;
  --primary-600: #099de6;
  --primary-700: #0689d2;
  --primary-800: #0678be;
  --primary-900: #03589c;
  --gray-10: #fcfbfb;
  --gray-50: #fcf9f9;
  --gray-100: #f7f4f4;
  --gray-200: #f0eeee;
  --gray-300: #e2e0e0;
  --gray-400: #bfbdbd;
  --gray-500: #a09e9e;
  --gray-600: #777575;
  --gray-700: #636161;
  --gray-800: #444242;
  --gray-900: #232121;
  --warm-gray-50: #f4f2ee;
  /*linkedin gray*/
  --danger-900: #ba0000;
  --danger-700: #d62218;
  --danger-400: #f24b3d;
  --danger-200: #f39691;
  --danger-50: #fbd8d6;
  --warning-800: #fc8a00;
  --warning-600: #faaf00;
  --warning-400: #f9c700;
  --warning-200: #fbde7b;
  --success-900: #006d14;
  --success-700: #209e36;
  --success-500: #39c04b;
  --success-200: #a3e0a8;
  --success-100: #dbf8dd;
  /* Input Styling */
  --color-outline-normal: var(--gray-500);
  --color-outline-disabled: var(--gray-400);
  --color-outline-hover: var(--secondary-100);
  --color-input-normal: var(--primary-500);
  --color-input-selected: var(--primary-800);
  --color-input-disabled: var(--gray-200);
  --color-input-secondary: var(--secondary-500);
  --color-background-primary: var(--primary-50);
  --color-card-border: var(--gray-300);
  --color-text-paragraph: var(--gray-800);
  --color-text-description: var(--gray-600);
  --color-text-input: var(--gray-900);
  --color-background-canvas: white;
  --color-background-canvas-mid: #fbfaf7;
  /* Thicknesses and Heights */
  --border-thickness-normal: 1px;
  --border-thickness-selected: 2px;
  --shadow-thickness: 0.25rem;
  --radio-input-height: 1.5rem;
  /* Border Radius */
  --button-border-radius: 0.25rem;
  --card-border-radius: 0.5rem;
  --pill-border-radius: 2rem;
  /* Animations */
  --animation-time: 0.6s;
  --transition-time: .3s;
  --animation-fade-in: fade-in var(--animation-time, .4s) backwards ease;
  --animation-fade-in-slide-right: fade-in var(--animation-time, .4s) backwards ease, slide-right var(--animation-time, .4s) backwards ease;
  --animation-fade-in-slide-left: fade-in var(--animation-time, .4s) backwards ease, slide-left var(--animation-time, .4s) backwards ease;
  --animation-fade-in-slide-up: fade-in var(--animation-time, .4s) backwards ease, slide-up var(--animation-time, .4s) backwards ease;
  --animation-fade-in-slide-down: fade-in var(--animation-time, .4s) backwards ease, slide-down var(--animation-time, .4s) backwards ease;
  /* z-index */
  --z-index-default: 1;
  --z-index-intermediate: 5;
  --z-index-nav: 10;
  --z-index-toast: 20;
  --z-index-tooltip: 50;
  /* Font Sizes */
  --size-xxx-large: 2rem;
  --size-xx-large: 1.625rem;
  --size-x-large: 1.4375rem;
  --size-large: 1.1875rem;
  --size-normal: 1rem;
  --size-small: 0.93755rem;
  --size-x-small: 0.85rem;
  --size-xx-small: 0.75rem;
  /* Font Weights */
  --weight-bold: 600;
  --weight-normal: 400;
  /* Fonts */
  --app-fonts: Avenir, Helvetica, Arial, sans-serif;
  /* Application Settings */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  font-family: var(--app-fonts);
  color: var(--color-text-paragraph);
  /* App Dimensions */
  display: flex;
  align-items: center;
  position: relative;
  /*new */
  min-height: 100vh;
  /* background: linear-gradient(var(--color-background-canvas) 0px, var(--color-background-canvas) 43px, var(--warm-gray-50) 43px, var(--primary-10)) ; */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpath fill='%23f6f3f0' d='M486 705.8c-109.3-21.8-223.4-32.2-335.3-19.4C99.5 692.1 49 703 0 719.8V800h843.8c-115.9-33.2-230.8-68.1-347.6-92.2C492.8 707.1 489.4 706.5 486 705.8z'/%3E%3Cpath fill='%23f7f5f2' d='M1600 0H0v719.8c49-16.8 99.5-27.8 150.7-33.5c111.9-12.7 226-2.4 335.3 19.4c3.4 0.7 6.8 1.4 10.2 2c116.8 24 231.7 59 347.6 92.2H1600V0z'/%3E%3Cpath fill='%23f9f6f5' d='M478.4 581c3.2 0.8 6.4 1.7 9.5 2.5c196.2 52.5 388.7 133.5 593.5 176.6c174.2 36.6 349.5 29.2 518.6-10.2V0H0v574.9c52.3-17.6 106.5-27.7 161.1-30.9C268.4 537.4 375.7 554.2 478.4 581z'/%3E%3Cpath fill='%23faf8f7' d='M0 0v429.4c55.6-18.4 113.5-27.3 171.4-27.7c102.8-0.8 203.2 22.7 299.3 54.5c3 1 5.9 2 8.9 3c183.6 62 365.7 146.1 562.4 192.1c186.7 43.7 376.3 34.4 557.9-12.6V0H0z'/%3E%3Cpath fill='%23FCF9F9' d='M181.8 259.4c98.2 6 191.9 35.2 281.3 72.1c2.8 1.1 5.5 2.3 8.3 3.4c171 71.6 342.7 158.5 531.3 207.7c198.8 51.8 403.4 40.8 597.3-14.8V0H0v283.2C59 263.6 120.6 255.7 181.8 259.4z'/%3E%3Cpath fill='%23faf7f7' d='M1600 0H0v136.3c62.3-20.9 127.7-27.5 192.2-19.2c93.6 12.1 180.5 47.7 263.3 89.6c2.6 1.3 5.1 2.6 7.7 3.9c158.4 81.1 319.7 170.9 500.3 223.2c210.5 61 430.8 49 636.6-16.6V0z'/%3E%3Cpath fill='%23f8f6f6' d='M454.9 86.3C600.7 177 751.6 269.3 924.1 325c208.6 67.4 431.3 60.8 637.9-5.3c12.8-4.1 25.4-8.4 38.1-12.9V0H288.1c56 21.3 108.7 50.6 159.7 82C450.2 83.4 452.5 84.9 454.9 86.3z'/%3E%3Cpath fill='%23f7f4f4' d='M1600 0H498c118.1 85.8 243.5 164.5 386.8 216.2c191.8 69.2 400 74.7 595 21.1c40.8-11.2 81.1-25.2 120.3-41.7V0z'/%3E%3Cpath fill='%23f5f3f3' d='M1397.5 154.8c47.2-10.6 93.6-25.3 138.6-43.8c21.7-8.9 43-18.8 63.9-29.5V0H643.4c62.9 41.7 129.7 78.2 202.1 107.4C1020.4 178.1 1214.2 196.1 1397.5 154.8z'/%3E%3Cpath fill='%23F3F1F1' d='M1315.3 72.4c75.3-12.6 148.9-37.1 216.8-72.4h-723C966.8 71 1144.7 101 1315.3 72.4z'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
  --background-image: 'by SVGBackgrounds.com https://www.svgbackgrounds.com/set/free-svg-backgrounds-and-patterns';
}

*,
*:before,
*:after {
  padding: 0;
  box-sizing: border-box;
}

#nav {
  background: var(--color-background-canvas);
  border-bottom: 2px solid;
  border-image: linear-gradient(to right, var(--secondary-500) 0px, var(--secondary-500) 200px, var(--secondary-500) 200px, var(--primary-500) 100%);
  border-image-slice: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 44px;
  padding: 5px 20px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-index-nav);
}

.Scaffold {
  padding-top: 45px;
  max-width: min(100%, 1920px);
  display: flex;
  flex-direction: column;
  height: 100%;
  /* height: calc(100vh - 45px); */
}

.CoverLetterWidth {
  width: 100%;
  max-width: calc(var(--editor-panel-max-width) + 840px);
}

.Background {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: calc(100vh - 43px);
  margin-bottom: 0;
  align-items: center;
}

.ContentContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;
  height: 100%;
  width: 100%;
  overflow: hidden;
  margin-bottom: 0;
  border-left: var(--border-thickness-normal) solid var(--color-card-border);
  border-right: var(--border-thickness-normal) solid var(--color-card-border);
  border-top-left-radius: var(--card-border-radius);
  border-top-right-radius: var(--card-border-radius);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpath fill='%23f9ebf3' d='M486 705.8c-109.3-21.8-223.4-32.2-335.3-19.4C99.5 692.1 49 703 0 719.8V800h843.8c-115.9-33.2-230.8-68.1-347.6-92.2C492.8 707.1 489.4 706.5 486 705.8z'/%3E%3Cpath fill='%23faeef4' d='M1600 0H0v719.8c49-16.8 99.5-27.8 150.7-33.5c111.9-12.7 226-2.4 335.3 19.4c3.4 0.7 6.8 1.4 10.2 2c116.8 24 231.7 59 347.6 92.2H1600V0z'/%3E%3Cpath fill='%23faf2f6' d='M478.4 581c3.2 0.8 6.4 1.7 9.5 2.5c196.2 52.5 388.7 133.5 593.5 176.6c174.2 36.6 349.5 29.2 518.6-10.2V0H0v574.9c52.3-17.6 106.5-27.7 161.1-30.9C268.4 537.4 375.7 554.2 478.4 581z'/%3E%3Cpath fill='%23fbf6f7' d='M0 0v429.4c55.6-18.4 113.5-27.3 171.4-27.7c102.8-0.8 203.2 22.7 299.3 54.5c3 1 5.9 2 8.9 3c183.6 62 365.7 146.1 562.4 192.1c186.7 43.7 376.3 34.4 557.9-12.6V0H0z'/%3E%3Cpath fill='%23FCF9F9' d='M181.8 259.4c98.2 6 191.9 35.2 281.3 72.1c2.8 1.1 5.5 2.3 8.3 3.4c171 71.6 342.7 158.5 531.3 207.7c198.8 51.8 403.4 40.8 597.3-14.8V0H0v283.2C59 263.6 120.6 255.7 181.8 259.4z'/%3E%3Cpath fill='%23f7f8fa' d='M1600 0H0v136.3c62.3-20.9 127.7-27.5 192.2-19.2c93.6 12.1 180.5 47.7 263.3 89.6c2.6 1.3 5.1 2.6 7.7 3.9c158.4 81.1 319.7 170.9 500.3 223.2c210.5 61 430.8 49 636.6-16.6V0z'/%3E%3Cpath fill='%23f2f7fb' d='M454.9 86.3C600.7 177 751.6 269.3 924.1 325c208.6 67.4 431.3 60.8 637.9-5.3c12.8-4.1 25.4-8.4 38.1-12.9V0H288.1c56 21.3 108.7 50.6 159.7 82C450.2 83.4 452.5 84.9 454.9 86.3z'/%3E%3Cpath fill='%23ecf7fc' d='M1600 0H498c118.1 85.8 243.5 164.5 386.8 216.2c191.8 69.2 400 74.7 595 21.1c40.8-11.2 81.1-25.2 120.3-41.7V0z'/%3E%3Cpath fill='%23e7f6fd' d='M1397.5 154.8c47.2-10.6 93.6-25.3 138.6-43.8c21.7-8.9 43-18.8 63.9-29.5V0H643.4c62.9 41.7 129.7 78.2 202.1 107.4C1020.4 178.1 1214.2 196.1 1397.5 154.8z'/%3E%3Cpath fill='%23E1F5FE' d='M1315.3 72.4c75.3-12.6 148.9-37.1 216.8-72.4h-723C966.8 71 1144.7 101 1315.3 72.4z'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
}

.ToastWellHeader {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  width: 1px;
}

.ToastInstructions {
  height: 1px;
  width: 1px;
  overflow: hidden;
  position: absolute;
}

/* ---------------Text Styles ---------------------- */
.TextStatusIndicator {
  position: relative;
  font-size: var(--size-normal);
  font-weight: var(--weight-bold);
  line-height: var(--size-x-large);
  text-align: right;
  color: var(--color-text-description);
}

.TextTitle {
  position: relative;
  font-size: var(--size-xxx-large);
  font-weight: var(--weight-bold);
  margin: 1.5rem 0 0 0;
}

.TextHeading1 {
  position: relative;
  font-size: var(--size-x-large);
  font-weight: var(--weight-bold);
  /* line-height: var(--size-x-large); */
  color: var(--color-input-selected);
  text-align: left;
}

.TextHeading2 {
  position: relative;
  font-size: var(--size-large);
  font-weight: var(--weight-normal);
  ;
  line-height: var(--size-large);
  text-align: left;
}

.TextParagraph {
  position: relative;
  font-size: var(--size-normal);
  font-weight: var(--weight-normal);
  line-height: var(--size-normal);
  text-align: left;
}

.TextPreview {
  /*A variation of TextParagraph used in the 'pre' element */
  font-size: var(--size-small);
  font-weight: var(--weight-normal);
  line-height: var(--size-small);
  color: var(--color-text-description);
  text-align: justify;
}

.TextDescription {
  position: relative;
  font-size: var(--size-small);
  font-weight: var(--weight-normal);
  line-height: var(--size-large);
  color: var(--color-text-description);
  text-align: left;
}

.TextMini {
  position: relative;
  font-size: var(--size-xx-small);
  font-weight: var(--weight-normal);
  text-align: left;
}

.ChartLabelText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: var(--size-x-large);
  font-weight: var(--weight-bold);
  /* color: var(--primary-900); */
}

.TextTruncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
}

.VerticalSeparator {
  height: 60%;
  width: 0.125rem;
  background-color: var(--gray-300);
}

/* --------- Custom Inputs and Elements ------------ */

/* Horizontal Rule */
hr {
  width: 100%;
}


/* Dropdown List */
select {
  font-size: 0.75rem;
  font-weight: var(--weight-normal);
  height: 20.25rem;
  margin-left: 0.625rem;
}

/* Text Area */
textarea {
  padding: 5px;
}

/* Preview Area */
pre {
  font-family: var(--app-fonts);
  white-space: pre-wrap;
}

/* -------- Custom Button Styles ------------*/
button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 0.85rem;
  margin: 0;
  height: 100%;
  font-size: var(--size-normal);
  transition: backgound-color var(--transition-time) ease-in-out;
  border-radius: var(--button-border-radius);
  box-sizing: border-box;
}

/* -------- Custom Text Area Input ----------- */

textarea {
  display: block;
  width: 100%;
  padding: 0.3125rem 0.625rem;
  box-sizing: border-box;
  border: var(--border-thickness-selected) solid;
  box-shadow: 0 0 0 var(--shadow-thickness) transparent;
  border-radius: var(--button-border-radius);
  font-size: var(--size-x-small);
  font-family: var(--app-fonts);
  outline: none;
  background-color: transparent;
}

/* -------- Custom Text Input ---------- */
input {
  display: block;
  width: 100%;
  padding: 0.3125rem 0.625rem;
  box-sizing: border-box;
  border: var(--border-thickness-selected) solid;
  box-shadow: 0 0 0 var(--shadow-thickness) transparent;
  border-radius: var(--button-border-radius);
  font-size: var(--size-x-small);
  font-family: var(--app-fonts);
  color: var(--color-text-input);
  /*font color*/
  outline: none;
  background-color: transparent;
}

.InputLabel {
  position: absolute;
  top: -1.1rem;
  font-size: var(--size-xx-small);
  padding: 0 0.25rem;
  color: inherit;
}

.ThemedInput {
  /*normal state*/
  border-color: var(--color-outline-normal);
  box-shadow: 0 0 0 var(--shadow-thickness) transparent;
  background-color: transparent !important;
  line-height: 1.25rem;
}

.ThemedInput:invalid {
  border-color: var(--danger-700);
  box-shadow: 0 0 0 var(--shadow-thickness) transparent;
  caret-color: var(--danger-700);
  background-color: transparent;
  outline: none;
}

.ThemedInput:hover {
  border-color: var(--color-input-normal);
  box-shadow: 0 0 0 var(--shadow-thickness) transparent;
  caret-color: var(--color-input-normal);
  background-color: transparent;
  outline: none;
}

.ThemedInput:focus {
  border-color: var(--color-input-selected);
  box-shadow: 0 0 0 var(--shadow-thickness) var(--color-outline-hover);
  caret-color: var(--color-input-selected);
  background-color: transparent !important;
  outline: none;
}

.ThemedInput:disabled {
  cursor: not-allowed;
  color: var(--color-outline-normal);
  background-color: var(--color-input-disabled);
  border: var(--border-thickness-normal) solid var(--color-outline-disabled);
  box-shadow: 0 0 0 var(--shadow-thickness) transparent;
  outline: none;
}

/* ------ Custom File Input -------- */

.ThemedInput input[type="file"] {
  display: none;
  /* Hide the default file input button */
}

.ThemedInput::file-selector-button {
  /* Apply custom styling to the file selector button */
  display: inline-block;
  position: relative;
  overflow: hidden;
  padding: 0.125rem 0.5rem;
  font-size: var(--size-normal);
  font-weight: var(--weight-normal);
  border-radius: var(--button-border-radius);
  color: var(--color-input-normal);
  /* Font color */
  background-color: var(--color-background-canvas);
  border: var(--border-thickness-normal) solid var(--color-input-normal);
  outline: none;
  cursor: pointer;
}

/* ------ Custom Date Picker/ Input -------- */
.ThemedInput[type="date"] {
  min-width: 9rem;
  /* Set a minimum width to prevent the date from being cut off */
}

/* Style the placeholder (shown when no date is selected) */
.ThemedInput[type="date"]::-webkit-datetime-edit-text,
.ThemedInput[type="date"]::-webkit-datetime-edit-month-field,
.ThemedInput[type="date"]::-webkit-datetime-edit-day-field,
.ThemedInput[type="date"]::-webkit-datetime-edit-year-field {
  color: var(--color-text-description);
}

/* Style the placeholder when empty */
.ThemedInput[type="date"]:in-range::-webkit-datetime-edit-year-field,
.ThemedInput[type="date"]:in-range::-webkit-datetime-edit-month-field,
.ThemedInput[type="date"]:in-range::-webkit-datetime-edit-day-field {
  opacity: 0.7;
}

/* Hover State: Placeholder style (shown when no date is selected) */
.ThemedInput[type="date"]:hover::-webkit-datetime-edit-text,
.ThemedInput[type="date"]:hover::-webkit-datetime-edit-month-field,
.ThemedInput[type="date"]:hover::-webkit-datetime-edit-day-field,
.ThemedInput[type="date"]:hover::-webkit-datetime-edit-year-field {
  color: var(--color-input-normal);
}

/* Hover state for calendar icon */
.ThemedInput[type="date"]:hover::-webkit-calendar-picker-indicator {
  cursor: pointer;
  filter: invert(48%) sepia(95%) saturate(1000%) hue-rotate(-168deg) brightness(102%) contrast(101%);
}

/* Disabled state specifics for date input */
.ThemedInput[type="date"]:disabled::-webkit-calendar-picker-indicator {
  opacity: 0.3;
  cursor: not-allowed;
}

/* --------------- Custom Radio Input Styles -------------*/
.ThemedRadioButton {
  position: relative;
  display: inline-block;
  width: var(--radio-input-height);
  height: var(--radio-input-height);
}

.ThemedRadioButton::before {
  /* Normal state - outer circle*/
  content: '';
  display: inline-block;
  border-radius: 50%;
  height: var(--radio-input-height);
  width: var(--radio-input-height);
  position: absolute;
  left: 0;
  top: 0;
  border: var(--border-thickness-selected) solid var(--color-outline-normal);
  cursor: pointer;
}

.ThemedRadioButton::after {
  /* Normal State- inner circle */
  content: '';
  display: inline-block;
  border-radius: 50%;
  width: var(--radio-input-height);
  height: var(--radio-input-height);
  position: absolute;
  top: 0;
  left: 0;
}

.ThemedRadioButton:focus::before {
  /* Hover state - Outer circle */
  background-color: var(--color-outline-hover);
  border: var(--border-thickness-selected) solid var(--color-input-selected);
  box-shadow: 0 0 0 var(--shadow-thickness) var(--color-outline-hover);
  transition: box-shadow var(--transition-time) ease-out;
}

.ThemedRadioButton:hover::before {
  /* Focus state (for keyboard focus) - Outer circle */
  background-color: var(--color-background-primary);
  border: var(--border-thickness-selected) solid var(--color-input-normal);
  box-shadow: 0 0 0 var(--shadow-thickness) var(--color-background-primary);
  transition: box-shadow var(--transition-time) ease-out;
}


.ThemedRadioButton:checked::after {
  /* Checked state - inner circle */
  background-color: var(--color-input-normal);
  transform: scale(.8);
  border: var(--shadow-thickness) solid var(--color-background-canvas);
}

.ThemedRadioButton:checked:hover::after {
  /* Checked state - inner circle hover */
  background-color: var(--color-input-selected);
  transform: scale(.8);
  border: var(--shadow-thickness) solid var(--color-background-canvas);
}

.ThemedRadioButton:checked:focus::after {
  /* Checked state - inner circle focus */
  background-color: var(--color-input-selected);
  transform: scale(.8);
  border: var(--shadow-thickness) solid var(--color-background-canvas);
}

.ThemedRadioButton:checked::before {
  /* Checked state - outer circle*/
  background-color: var(--color-input-normal);
  border: var(--border-thickness-selected) solid var(--color-input-normal);
}

.ThemedRadioButton:checked:focus::before {
  /* Checked state - Outer circle  - focus*/
  background-color: var(--color-outline-hover);
  border: var(--border-thickness-selected) solid var(--color-input-selected);
  box-shadow: 0 0 0 var(--shadow-thickness) var(--color-outline-hover);
  transition: box-shadow var(--transition-time) ease-out;
}

.ThemedRadioButton:checked:hover::before {
  /* Checked state - Outer circle  - hover*/
  background-color: var(--color-background-primary);
  border: var(--border-thickness-selected) solid var(--color-input-selected);
  box-shadow: 0 0 0 var(--shadow-thickness) var(--color-background-primary);
  transition: box-shadow var(--transition-time) ease-out;
}

.ThemedRadioButton:disabled::before {
  /* Disabled state - inner circle*/
  transform: scale(0);
}

.ThemedRadioButton:disabled::after {
  /* Disabled state - outer circle */
  background-color: transparent;
  border: solid var(--border-thickness-selected) var(--color-outline-disabled);
  cursor: not-allowed;
}

/* ------------ End Custom Radio Input Styles ------------- */

.TertiaryButton {
  border: none;
  outline: none;
  padding: 0 0 0.25rem 0;
  margin: 0;
  background-color: transparent;
  font-size: var(--size-normal);
  font-weight: var(--weight-normal);
  ;
  line-height: var(--size-normal);
  color: var(--color-input-selected);
  border-radius: 0;
  cursor: pointer;
}

.TertiaryButton:hover,
.TertiaryButton:focus {
  color: var(--color-input-normal);
  text-decoration: none;
}

.TertiaryButton:active {
  color: var(--color-input-selected);
  transform: scale(0.9);
}

/* ---------- End Custom Inputs and Elements --------------- */

.StickyContainer {
  width: 100%;
  background-color: var(--color-background-canvas);
  position: sticky;
  top: 0;
  z-index: var(--z-index-intermediate);
  display: flex;
  flex-direction: row;
  padding: 0 0.625rem;
}

.TabMenu {
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 2.25rem;
  background-color: var(--color-background-canvas);
  padding: 0 1.25rem 0 1.25rem;
  border-bottom: solid var(--color-card-border) var(--border-thickness-normal);
  gap: var(--shadow-thickness);
  position: sticky;
  /* top: 5.0625rem; */
}

.TopMenuContainer {
  min-width: 100%;
  background-color: var(--color-background-canvas);
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  z-index: 2;
}

.CoverLetterPanelContainer {
  height: calc(100% - 5.0625rem);
  /* subtract 81px for the top menu */
  width: 100%;
  padding: 0 0.625rem;
  overflow: hidden;
  position: absolute;
  top: 5.0625rem;
}

.TermsPanelContainer {
  height: 100%;
  /* subtract 81px for the top menu */
  width: 100%;
  padding: 0 0.625rem;
  overflow: hidden;
}

.TitleBar {
  height: 2.75rem;
  display: flex;
  align-items: center;
}

.TopMenuScaffold {
  display: flex;
  align-items: flex-end;
  background-color: var(--color-background-canvas);
  width: 100%;
  margin: 0.625rem 0;
  height: 3.75rem;
}

.TopMenuRow {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 0.625rem;
  background-color: var(--color-background-canvas);
  width: 100%;
  height: 2.75rem;
  gap: 0.5rem;
}

.SubMenuRow {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% - 1.25rem);
  /* subtract 1 rem for the margins */
  height: 2rem;
  padding: 0 0.625rem;
  margin: 0 0.625rem 0.25rem 0.625rem;
  background-color: var(--gray-50);
  border-radius: var(--pill-border-radius);
  gap: 0.25rem;
}

.SubMenuRightButtonPanel {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  gap: 0.75rem;
  color: var(--color-text-paragraph);
}

.TopMenuWrapper {
  width: calc(100% - 1rem);
  height: 4.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0 0.5rem 0.5rem 0.5rem;
}

.PageHeaderNav {
  height: 2.75rem;
  /* Available height=80px - MenuTray height (=44px) */
  margin-bottom: 0.125rem;
  /* width: calc( Min( 100vw, max(90vw,1920px)) - var(--editor-panel-max-width) - 20px); */
  width: 100%;
  /* padding: 0 0.5rem; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
  z-index: var(--z-index-nav);
}

.MenuTray {
  top: 2.75rem;
  /* Available height=80px, element height = 32px, thus bottom margin = 48px - top(=44px) */
  height: 2rem;
  margin-bottom: 0.125rem;
  /* width: calc( Min( 100vw, max(90vw,1920px)) - var(--editor-panel-max-width) - 20px); */
  width: 100%;
  padding: 0 0.5rem;
  display: flex;
  background-color: var(--gray-100);
  justify-content: flex-start;
  align-items: center;
  border-radius: var(--pill-border-radius);
  gap: 0.25rem;
  z-index: var(--z-index-nav);
}

.PageHeaderIconButton {
  width: 2.0rem;
  height: 2.0rem;
  aspect-ratio: 1 / 1;
  align-self: center;
}

.PageHeaderTextButton {
  height: 2.0rem;
  align-self: center;
  /* width: 14.5rem; */
}

.MenuAlignedRight {
  margin-right: 1.625rem;
}

.InputError {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  gap: 0.625rem;
  padding: 0.5rem 0 0 0;
}

.InputErrorIconContainer {
  height: 1rem;
  width: 1rem;
  aspect-ratio: 1 / 1;
}

.CommentIconContainerMargin {
  z-index: var(--z-index-tooltip);
  position: absolute;
}

.CommentIconContainerInline {
  z-index: var(--z-index-tooltip);
  position: absolute;
  left: auto;
  right: 0;
  top: 0;
}

.CommentIcon {
  display: inline-flex;
  position: relative;
  height: 1.5rem;
  width: 1.5rem;
  aspect-ratio: 1 / 1;
  color: var(--color-input-secondary);
  cursor: pointer;
}

.CommentIndex {
  position: absolute;
  left: 50%;
  /* Center horizontally */
  top: 45%;
  /* Center vertically */
  transform: translate(-50%, -50%);
  /* Adjust for the element's own size */
  font-size: var(--size-xx-small);
  font-weight: var(--weight-bold);
  text-align: center;
  /* Ensure text is centered if it wraps */
  width: 100%;
  /* Take full width of parent */
}

.CommentIconContainerInline .CommentIndex {
  top: 40%;
  /* Center vertically */
}

.CommentIconContainer {
  display: inline-flex;
  height: 1.5rem;
  width: 1.5rem;
  color: var(--color-input-secondary);
  z-index: var(--z-index-tooltip);
  position: absolute;
  /* left: 1.25rem; ; */
  cursor: pointer;
}

.CommentIndex {
  position: absolute;
  left: 50%;
  /* Center horizontally */
  top: 45%;
  /* Center vertically */
  transform: translate(-50%, -50%);
  /* Adjust for the element's own size */
  font-size: var(--size-xx-small);
  font-weight: var(--weight-bold);
  text-align: center;
  /* Ensure text is centered if it wraps */
  width: 100%;
  /* Take full width of parent */
}

/* ---- Editor Panel Settings ----------- */
.EditorPanel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: var(--editor-panel-max-width);
  /* 1920*11/32 - 30 */
  z-index: calc(var(--z-index-nav) - 1);
  /* transition: width var(--transition-time) ease; */
  /* background: linear-gradient(var(--color-background-canvas-mid) 0rem, var(--color-background-canvas-mid) 25rem,  var(--secondary-10)) ; */
}

.EditorPanelHeader {
  padding: 0.625rem;
  padding-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-self: flex-start;
  width: 100%;
  height: 3.25rem;
  gap: 0.625rem;
  ;
}

.EditorPanelHaderButton {
  width: 8.5rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.EditorPanelHeaderText {
  height: 2rem;
  width: calc(100% - 11.875rem);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: var(--size-large);
  font-weight: var(--weight-bold);
  ;
}

.EditorScaffold {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-right: var(--border-thickness-normal) solid var(--color-card-border);
  box-shadow: var(--shadow-elevation-four);
  /* transition: max-width var(--transition-time) ease-in-out; */
}

.LeftNavWidth {
  max-width: 4.25rem;
}

.PanelWidth {
  max-width: 100%;
}

.PanelToggleContainer {
  height: 3rem;
  width: 3rem;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
}

.PanelContainer {
  height: 100%;
  width: 100%;
  padding: 0.625rem;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
}

.ResumeAndCommentWrapper {
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  display: block;
}

.ResumeAndCommentPanel {
  /* A special version of Panel Container to hold the resume + commments */
  position: relative;
  align-self: flex-start;
  min-width: 832px;
  height: 100%;
  overflow-y: hidden;
}

.ResumeAndCommentScaffold {
  display: flex;
  justify-content: flex-start;
  padding: 0 0.625rem 0 1.25rem;
  /* min-height: calc( 100vh - 55px );  // top nav=45px + margin top = 10px */
  height: 100%;
  /* overflow-x: auto; */
}

.ResumePanel {
  width: 100%;
  /* height: calc(100vh - 46px -5rem;  // top nav=45px + sticky container = 81px */
  overflow: auto;
}

.ResumeContainer {
  width: 100%;
  overflow: auto;
}

.CustomSectionPanel {
  transition: max-height var(--transition-time) ease-in-out,
    transform var(--transition-time) ease-in-out,
}

.CustomSectionPanel.hide {
  transform: translateY(-500rem);
  max-height: 0;
}

.RegularCard {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: flex-start;
  min-width: 100%;
  padding: 0.625rem 0;
  transition: max-height var(--transition-time) ease-out;
  margin: 0 0 0.625rem 0;
  border: var(--border-thickness-normal) solid var(--color-card-border);
  border-radius: var(--card-border-radius);
  background: var(--color-background-canvas);
  box-shadow: var(--shadow-elevation-four);
}

.AltCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.625rem 0;
  margin: 0.625rem 0 0 0;
  border: var(--border-thickness-normal) solid var(--color-card-border);
  border-radius: var(--card-border-radius);
  background: var(--color-background-canvas);
}

.TableRowCard {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: flex-start;
  min-width: 100%;
  padding: 0.625rem 0;
  margin: 0 0 0.625rem 0;
  border: var(--border-thickness-normal) solid var(--color-card-border);
  border-radius: var(--card-border-radius);
  background: var(--color-background-canvas);
}

.CustomSectionPanel.sortable-chosen .RegularCard {
  background-color: var(--color-background-primary);
  /* Change the card background color when it is being dragged*/
}

/* The destination of the dragged item */
.CustomSectionPanel.sortable-ghost .RegularCard {
  background-color: var(--color-card-border);
  border-top: var(--border-thickness-normal) solid var(--color-text-description);
  border-bottom: var(--border-thickness-normal) solid var(--color-text-description);
}

.RegularCard:hover {
  box-shadow: 0 0 0 var(--border-thickness-normal) var(--color-input-normal);
}

.RegularCard:focus,
.RegularCard:active {
  box-shadow: 0 0 0 var(--border-thickness-normal) var(--color-input-selected);
}

.TableRowCard:hover {
  border-color: var(--color-input-normal);
  cursor: pointer;
}

.TableRowCard.active {
  border-color: var(--color-input-normal);
  box-shadow: var(--shadow-elevation-three);
}

.ChildCard {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: flex-start;
  min-width: 100%;
  transition: max-height var(--transition-time) ease-out;
  padding: 0.625rem 0;
  margin: 0.625rem 0;
  border: var(--border-thickness-normal) solid var(--color-card-border);
  border-radius: var(--card-border-radius);
  background-color: var(--gray-10);
}

.ChildCard:hover {
  border: var(--border-thickness-normal) solid var(--color-outline-hover);
}

.ChildCard:focus,
.ChildCard:active {
  border: var(--border-thickness-normal) solid var(--color-outline-hover);
  transition: border var(--transition-time) ease-in-out;
}

.ChildCard.sortable-chosen {
  background-color: var(--color-background-primary);
  /* Change the card background color when it is being dragged*/
}

/* The destination of the dragged item */
.ChildCard.sortable-ghost {
  background-color: var(--color-card-border);
  border-top: var(--border-thickness-normal) solid var(--color-text-description);
  border-bottom: var(--border-thickness-normal) solid var(--color-text-description);
}

.CardConnector {
  height: 2rem;
  width: 2rem;
  align-self: center;
  color: var(--color-outline-normal);
}

.RegularCardSection {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: flex-start;
  width: 100%;
  max-height: 1000rem;
  transition: max-height var(--transition-time) ease-in-out;
  transform-origin: top left;
  overflow: hidden;
}

.RegularCardSection.collapsed {
  max-height: 0;
}

.CardHeaderRow {
  box-sizing: border-box;
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  padding: 0.3125rem 0.625rem 0.3125rem 1.25rem;
  align-items: center;
  justify-content: space-between;
  /* Aligns items to each end of the container */
  align-self: flex-start;
  width: 100%;
  height: 2.375rem;
  position: relative;
}

.CardHeaderRow.hide {
  display: none
}

.CardHeaderRow.edit {
  justify-content: flex-start;
  border-bottom: var(--border-thickness-normal) solid var(--color-card-border);
  padding-bottom: 1rem;
}

.CardHeaderEditorConfirmation {
  width: 2.5rem;
  height: 2rem;
  margin: 0 0.625rem 0 0;
  color: var(--color-background-canvas);
}

.CardDoubleHeaderRow {
  box-sizing: border-box;
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  padding: 0.3125rem 0.625rem 0.3125rem 1.25rem;
  gap: 0.625rem;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: flex-start;
  width: 100%;
}

.CardButtonRow {
  display: flex;
  justify-content: space-between;
  margin: 0.625rem 0;
  min-height: 2rem;
  flex-direction: row;
  width: calc(100% - 2.5rem);
  align-items: center;
  align-self: center;
}

.DraggableHeader {
  /* Applied as a customization to .CardHeaderRow */
  padding-left: 0.25rem;
  color: var(--gray-300);
}

.DraggableHeader:hover {
  /* Applied as a customization to .CardHeaderRow */
  color: var(--color-input-normal);
}

.CountBubble {
  width: 1.5rem;
  height: 1.5rem;
  aspect-ratio: 1/1;
  border-radius: 50%;
  /* Ensures the element is a circle */
  background-color: var(--secondary-50);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--size-xx-small);
  font-weight: var(--weight-bold);
  padding: 0.25rem;
  color: var(--color-text-paragraph);
}

.CardHeaderHorizontalGroup {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% - 7.75rem);
  gap: 0.625rem;
  margin-right: 0.625rem;
}

.ChildCardHeaderWidth {
  width: calc(100% - 5rem);
}

.DegreeCardHeaderWidth {
  width: calc(100% - 8.75rem);
}

.RowAlignedLeft {
  box-sizing: border-box;
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  gap: 0.625rem;
  align-items: center;
  justify-content: flex-start;
  padding: 0.625rem 1.25rem 0.625rem 1.25rem;
  align-self: flex-start;
  width: 100%;
}

/* When item is being dragged */
.RowAlignedLeft.sortable-chosen {
  background-color: var(--color-background-primary);
}

/* The destination of the dragged item */
.RowAlignedLeft.sortable-ghost {
  background-color: var(--color-card-border);
  border-top: var(--border-thickness-normal) dashed var(--color-text-description);
  border-bottom: var(--border-thickness-normal) dashed var(--color-text-description);
}

.RowAlignedCenterWithMargin {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.25rem 1rem 0.4rem 1rem;
}

.HorizontalGroup {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 0.625rem;
  margin-right: 0.625rem;
}

/* Rule specifically for custom section rows to minimize the gap between the draggable indicator and the text input */
.RowAlignedLeft>.HorizontalGroup {
  margin-left: -0.625rem;
  margin-right: 0;
}

.CommentHeaderRow>.HorizontalGroup {
  width: calc(100% - 2.5rem);
}

.VerticalGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 0.625rem;
}

.Pill {
  background-color: var(--color-input-disabled);
  padding: 0.25rem 1rem;
  border-radius: var(--pill-border-radius);
  font-size: var(--size-x-small);
  font-weight: var(--weight-normal);
  ;
  color: var(--color-text-paragraph);
  max-width: 100%;
  text-align: center;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}


.RadioButtonDescriptionRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 2.625rem;
  padding: 0 1.25rem;
  gap: 0.625rem;
  width: calc(100% - 3.875rem);
  /* Occupy 100% of the row width  -2.625rem (left margin) -1.25rem (left padding) */
  white-space: nowrap;
}

.RadioButtonDateRow {
  padding: 0.25rem 0 0 1.25rem;
  width: calc(100% - 5.125rem);
  /* Occupy 100% of the row width  -2.625rem (left margin) -2*1.25rem (left/right padding) */
  white-space: nowrap;
  color: var(--color-text-description);
}

.RadioButtonTextRow {
  position: relative;
  left: 2.5rem;
  max-width: 31.25rem;
  width: calc(100% - 2.8125rem);
  /* Occupy 100% of the row width  -35px to account for the 30px space on the left and 10px for margin on the right*/
  margin-top: 0.25rem;
  display: flex;
  justify-content: flex-start;
  color: var(--gray-700);
}

.RadioButtonTextRow:hover {
  color: var(--color-input-normal);
}

.RadioButtonTextRow:focus {
  color: var(--color-input-selected);
}

.ChildCardRow {
  position: relative;
  display: flex;
  width: 100%;
  color: var(--gray-700);
}

.ChildCardRow:hover {
  color: var(--color-input-normal);
}

.ChildCardRow:focus {
  color: var(--color-input-selected);
}

.CardIconButton {
  width: 1.75rem;
  height: 1.75rem;
  aspect-ratio: 1 / 1;
  margin: 0 0.625rem 0 0;
  align-self: center;
}

.DoubleHeaderCardIconButton {
  width: 1.75rem;
  height: 1.75rem;
  aspect-ratio: 1 / 1;
}

.CardToggleButton {
  /* A larger version of the CardIconButton */
  width: 3rem;
  height: 3rem;
  aspect-ratio: 1 / 1;
  margin: 0 0.625rem 0 0;
  align-self: center;
}

.ChildCardToggleButton {
  /* A smaller version of the CardToggleButton */
  width: 2.125rem;
  height: 2.125rem;
  aspect-ratio: 1 / 1;
  align-self: center;
}

.CardHeaderIndicator {
  height: 3rem;
  width: 1rem;
  align-self: center;
  display: flex;
  align-items: center;
  margin: 0 0.125rem;
  color: var(--gray-700);
  z-index: var(--z-index-tooltip);
}

.CardHeaderIndicator:hover {
  color: var(--color-input-normal);
  cursor: move;
}

.CardHeaderIcon {
  height: 1.5rem;
  width: 1.5rem;
  aspect-ratio: 1 / 1;
  align-self: center;
  color: var(--color-input-selected);
  margin-right: 0.5rem;
}

.CardDoubleHeaderIcon {
  height: 1.5rem;
  width: 1.5rem;
  aspect-ratio: 1 / 1;
  color: var(--color-input-selected);
}

.CardStatusIndicator {
  width: 1.5rem;
  height: 1.5rem;
  aspect-ratio: 1 / 1;
  position: absolute;
  top: -0.75rem;
  left: -0.75rem;
  background-color: var(--color-background-canvas);
  color: var(--color-outline-normal);
}

.CardHeaderIconContainer {
  border: var(--border-thickness-selected) solid var(--color-input-selected);
  border-radius: var(--button-border-radius);
  min-height: 3rem;
  min-width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.CardHeaderSection {
  display: flex;
  flex-direction: column;
  width: calc(100% - 8.375rem);
}

.ChildCardIconButton {
  width: 1.25rem;
  height: 1.25rem;
  aspect-ratio: 1 / 1;
}

.InputRowIconButton {
  width: 1.5rem;
  height: 1.5rem;
  aspect-ratio: 1 / 1;
  margin: 0 1rem;
  align-self: center;
}

.DescriptionRowIconButton {
  width: 1rem;
  height: 1rem;
  aspect-ratio: 1 / 1;
}

.IconContainerMini {
  height: 1.5rem;
  width: 1.5rem;
  aspect-ratio: 1 / 1;
  color: var(--color-text-paragraph);
  margin: var(--border-thickness-selected);
}
.InlineIcon{
  display: inline-flex;
  vertical-align: middle;
  height: 0.75rem;
  aspect-ratio: 1 / 1;
}

.ErrorStateContainer {
  width: 100%;
  height: 100%;
  background: linear-gradient(var(--color-background-canvas-mid) 0rem, var(--color-background-canvas-mid) 25rem, var(--secondary-10));
  display: block;
  opacity: 1;
  transition: opacity var(--animation-time) ease-in-out;
}

.ErrorStateContainer.Hide {
  display: none;
  /* Initially hide the element */
  opacity: 0;
  /* Set initial opacity to 0 */
}

.ErrorState {
  position: absolute;
  width: 36%;
  left: 32%;
  top: 10%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

/* ----- Table Settings ------------ */
.TablePanel {
  width: 100%;
  display: flex;
  gap: 0.625rem;
  padding: 0.625rem;
}

.TablePanelSection {
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
  width: calc(100% - 8.75rem);
}

.TableActionSection {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  width: 7.5rem;
}

.TableRowIcon {
  width: 1.25rem;
  aspect-ratio: 1 / 1;
  color: var(--gray-400);
}

.TableRowWidth {
  width: calc(100% - 2.25rem);
}

/* ----- Comment Panel Settings ------------ */
.CommentPanel {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 3rem;
  right: 0.625rem;
  border-left: var(--border-thickness-normal) solid var(--color-card-border);
  width: 22.5rem;
  height: 100%;
  background-color: var(--color-background-canvas);
  border-radius: var(--card-border-radius);
  box-shadow: var(--shadow-elevation-two);
  overflow-y: hidden;
  /* z-index: var(--z-index-intermediate); */
  transition: top var(--transition-time) ease-in-out;
}

.CommentContainer {
  width: 100%;
  overflow-y: auto;
}

.CommentScaffold {
  width: 100%;
  padding-bottom: 45px;
}

.CommentBulletContainer {
  color: var(--color-text-description);
}

.CommentPanelEmpty {
  position: absolute;
  top: 35%;
  width: 80%;
  left: 10%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
}

.CommentPanelEmptyIcon {
  width: 6rem;
  height: 6rem;
  aspect-ratio: 1 / 1;
  color: var(--success-500);
}

.ImpactPill {
  display: inline-block;
  vertical-align: middle;
  background-color: var(--color-input-disabled);
  padding: 0.125rem 0.5rem;
  border-radius: var(--pill-border-radius);
  font-size: var(--size-xx-small);
  font-weight: var(--weight-normal);
  color: var(--color-text-paragraph);
  text-align: center;
  margin-left: 0.25rem;
}

.KeywordPillMargin {
  margin: 0.25rem;
  max-width: 100%;
}

/* -------- Preview Panel Settings --------------------- */

.PreviewPanel {
  /* editor panel: preview panel split= 11:21 */
  /* padding: 0  0 0.625rem 0; */
  /*max-width: 1240px;*/
  /* 1920*21/32 - 20 */
  /* flex:21;  */
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  /* background: linear-gradient(var(--color-background-canvas-mid) 0rem, var(--color-background-canvas-mid) 25rem,  var(--secondary-10)) ; */
  /* new */
  overflow: hidden;
  /* min-width: 820px;
  min-height: calc( 100vh - 45px ); */
  width: 100%;
  height: 100%;
}

.PreviewScaffold {
  width: 800px;
  height: 100%;
  margin: 0 0.625rem;
  padding: 0.625rem;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}

.PreviewEmptyState {
  position: absolute;
  width: 50%;
  left: 25%;
  top: 20%;
  overflow: hidden;
  /* transition: transform var(--transition-time) ease; */
}

.CoverLetterPreview {
  width: 100%;
  word-wrap: break-word;
  padding: 4rem 2rem;
  background-color: var(--color-background-canvas);
  border-radius: var(--button-border-radius);
  /* border: var(--border-thickness-normal) solid var(--color-card-border); */
  box-shadow: var(--shadow-elevation-one);
  overflow: scroll;
  text-align: left;
}

.CoverLetterPreview ul {
  padding-left: 0.5rem;
  list-style: inside;
}

.CoverLetterPreview li {
  position: relative;
  padding-left: 1.5rem;
  text-indent: -1.5rem;
  margin-bottom: 0.5rem;
}

.PrintPreview {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100%;
  width: 100%;
  /* text-wrap: wrap; */
  word-wrap: break-word;
  padding: 4rem 2rem;
  background-color: var(--color-background-canvas);
  border-radius: var(--button-border-radius);
  overflow: hidden;
  transform: translateX(0);
  transition: opacity var(--animation-time) ease-in-out, transform var(--transition-time) ease-in-out;
  z-index: calc(var(--z-index-default) + 1);
}

.VerticalGroup {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: flex-start;
  width: 100%;
}

#ButtonContainer {
  z-index: 1;
  /* Ensure the menu is above other elements */
  position: sticky;
  top: 5px;
  display: flex;
  flex: auto;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: 50px;
  max-height: 40px;
}

._wrapper_1axvs_1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: fit-content;
  animation: var(--animation-fade-in-slide-down);
  animation-delay: .15s;
  /* scale to 800px wide: scale factor = 800/650=1.23076923 
  min-width: 820px;
  transform: scale(1.23076923);*/
  transform-origin: left top;
  margin: 0.625rem 0;
}

._container_1axvs_20 {
  width: 650px;
  /* Calculation needs to be revisted: calc((100vh - 100.25rem) * var(--aspect-ratio)); */
  overflow: hidden;
  border-radius: var(--button-border-radius);
  margin: 0;
}

._scaled_container {
  position: relative;
  width: fit-content;
}

.terms-content ol {
  padding-left: 2rem;
}

/* Loader/ spinner animation */
.loading-animation {
  width: 100%;
  /* Set the width of your loading animation */
  height: auto;
  /* Set the height of your loading animation */
  border: none;
  outline: none;
}

.loading-animation #rotating-path {
  /* anmiate the roating-path element of the SVG */
  transform-origin: 2rem 2rem;
  /* Set the center of rotation */
  animation: loadingAnimation 1000ms linear infinite forwards;
}

/* Keyframes for the loading animation */
@keyframes loadingAnimation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Wiggle animation */
@keyframes wiggleAnimation {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(5%);
  }
}

.WiggleBox {
  animation: wiggleAnimation 100ms linear 5 alternate;
}

/* Blinking animation */
.NotificationBubble {
  height: 1rem;
  aspect-ratio: 1 / 1;
  margin-left: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--danger-700);
}

/* ----- Style Overrides ---- */
.DisplayOn {
  display: block;
}

.DisplayOff {
  display: none;
}

.StrikeThrough {
  text-decoration: line-through;
  text-decoration-color: var(--color-text-description);
}

.TextAlignCenter {
  text-align: center;
}

.FontSmall {
  font-size: var(--size-x-small);
  margin-left: 0.25rem;
}

.FontBold {
  font-weight: var(--weight-bold);
}

.ErrorColor {
  color: var(--danger-700);
}

.WarningColor {
  color: var(--warning-600);
}

.SuccessColor {
  color: var(--success-500);
}

.ParagraphColor {
  color: var(--color-text-paragraph);
}

.DescriptionColor {
  color: var(--color-text-description);
}

.SelectedStateColor {
  color: var(--color-input-selected);
}

.ContrastColor {
  color: var(--color-background-canvas);
}

.ContrastColor {
  color: var(--color-background-canvas);
}

.RecommendationsColor {
  color: var(--primary-600);
}

.AlertBackground {
  background-color: var(--danger-700);
}

.Clickable {
  cursor: pointer;
}

.CardShadow {
  border: var(--border-thickness-normal) solid var(--color-card-border);
  border-radius: var(--button-border-radius);
  box-shadow: var(--shadow-elevation-four);
}

.InlineText {
  display: inline-block;
}

.NoWrap {
  white-space: nowrap;
}

.NoVerticalPadding {
  padding-top: 0;
  padding-bottom: 0;
}

.NoTopPadding {
  padding-top: 0;
}

.NoBottomPadding {
  padding-bottom: 0;
}

.NoHorizontalPadding {
  padding-left: 0;
  padding-right: 0;
}

.NoHorizontalSpace {
  margin: 0;
  gap: 0;
}

.BorderRight {
  border-right: var(--border-thickness-normal) solid var(--color-card-border);
}

.BorderTop {
  border-top: var(--border-thickness-normal) solid var(--color-card-border);
}

.BorderBottom {
  border-bottom: var(--border-thickness-normal) solid var(--color-card-border);
}

.NoTopMargin {
  margin-top: 0;
}

.NoBottomMargin {
  margin-bottom: 0;
}

.NoTopMargin {
  margin-top: 0;
}

.NoRightMargin {
  margin-right: 0;
}

.MarginBottom {
  margin-bottom: 0.625rem;
}

.MarginTop {
  margin-top: 0.625rem;
}

.MarginRight {
  margin-right: 0.625rem;
}

.MarginLeft {
  margin-left: 0.625rem;
}

.JustifyCenter {
  justify-content: center;
}

.JustifyRight {
  justify-content: flex-end;
}

.JustifyLeft {
  justify-content: flex-start;
}

.AlignTop {
  align-items: flex-start;
}

.ColumnDirection {
  flex-direction: column;
}