.Berlin ._header {
    position: relative;
    margin-bottom: 0;
    padding-bottom: calc(8px * var(--density-factor));
}

/* Horizontal separator after header */
.Berlin hr {
    width: 100%;
    border: none;
    border-top: 1px solid var(--color-primary);
    opacity: .12;
    margin: 2px 0;
}

.Berlin ._resume_section {
    margin-bottom: calc(4px * var(--density-factor));
    margin-top: calc(4px * var(--density-factor));
}

.Berlin ._personal_info_section {
    flex-direction: column;
    align-items: flex-start;
}

.Berlin ._contact_item:before {
    content: "\25CF";
    color: var(--color-primary);
    margin-right: 4px;
}

.Berlin ._custom_link:not(:first-child):before {
    content: "\25CF";
    color: var(--color-foreground);
    display: inline-block;
    margin-right: 4px;
}

.Berlin ._labelTop {
    display: block;
    width: 49px;
    height: 49px;
    color: var(--color-primary);
    /* Position it at the top right margin */
    right: 32px;
    top: 32px;
    transform: scale(var(--scale-factor));
    transform-origin: 100% 0;
}

.Berlin ._sidebar_column {
    width: var(--sidebar-width);
    flex-shrink: 0;
    gap: calc(12px * var(--density-factor));
}

.Berlin ._page_container {
    --color-foreground: #444242;
    --columns-gap: calc(30px * var(--density-factor));
}

.PositionFarLeft {
    left: -32px;
}

.Berlin .PositionTop {
    top: -24px;
}